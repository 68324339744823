// Secure ls
import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: '9qNiVtilyxVRshoBRw1qbCLinpaGKE_instaacard_FSbKmgokp7I4IRLgW2WiULFi5aDbfC_admin_iXuWzf0FAUJPfMBszksNCuNpzq0uWD_panel',
    isCompression: false
});
export const secureSet = (key, value) => {
    try {
        secureLs.set(key, value);
        return true;
    } catch (err) {
        return false;
    }
};

export const secureGet = (key, value) => {
    try {
        let data = secureLs.get(key, value);
        if (data) return data;
        return null;
    } catch (err) {
        return null;
    }
};

export default secureLs;
