import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getShippingRule = createAsyncThunk('shippingRuleSlice/getShippingRule', async () => {
    try {
        let response = await axios.get('/shipping-rule', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getShippingRuleById = createAsyncThunk('shippingRuleSlice/getShippingRuleById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/shipping-rule/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createShippingRule = createAsyncThunk('shippingRuleSlice/createShippingRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/shipping-rule', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Shipping Rule added successfully.');
        dispatch(getShippingRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateShippingRule = createAsyncThunk('shippingRuleSlice/updateShippingRule', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/shipping-rule/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Shipping Rule updated successfully.');
        dispatch(getShippingRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteShippingRule = createAsyncThunk('shippingRuleSlice/deleteShippingRule', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/shipping-rule/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Shipping Rule deleted successfully.');
        dispatch(getShippingRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateShippingRuleStatus = createAsyncThunk('shippingRuleSlice/updateShippingRuleStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/shipping-rule/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getShippingRule());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const shippingRuleSlice = createSlice({
    name: 'shippingRuleSlice',
    initialState: {
        data: [],
        selectedShippingRule: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getShippingRule.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getShippingRuleById.fulfilled, (state, action) => {
            state.selectedShippingRule = action.payload;
            return;
        });
    }
});

export default shippingRuleSlice.reducer;
