import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSite = createAsyncThunk('siteSlice/getSite', async () => {
    try {
        let response = await axios.get('/site', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSite = createAsyncThunk('siteSlice/createSite', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/site', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Site added successfully.');
        dispatch(getSite());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSiteById = createAsyncThunk('siteSlice/getSiteById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/site/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSite = createAsyncThunk('siteSlice/updateSite', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/site/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Site updated successfully.');
        dispatch(getSite());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSite = createAsyncThunk('siteSlice/deleteSite', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/site/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Site deleted successfully.');
        dispatch(getSite());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSiteStatus = createAsyncThunk('siteSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/site/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSite());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const siteSlice = createSlice({
    name: 'siteSlice',
    initialState: {
        data: [],
        selectedSite: {}
    },
    reducers: {
        removeSite: (state) => {
            state.selectedSite = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getSite.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSiteById.fulfilled, (state, action) => {
            state.selectedSite = action.payload;
            return;
        });
    }
});

export const { removeSite } = siteSlice.actions;
export default siteSlice.reducer;
