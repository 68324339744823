import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getSocialMedia = createAsyncThunk('socialMediaSlice/getSocialMedia', async () => {
    try {
        let response = await axios.get('/social-media-master', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSocialMediaById = createAsyncThunk('socialMediaSlice/getSocialMediaById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/social-media-master/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSocialMedia = createAsyncThunk('socialMediaSlice/createSocialMedia', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/social-media-master', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Social Media added successfully.');
        dispatch(getSocialMedia());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSocialMedia = createAsyncThunk('socialMediaSlice/updateSocialMedia', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/social-media-master/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Social Media updated successfully.');
        dispatch(getSocialMedia());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteSocialMedia = createAsyncThunk('socialMediaSlice/deleteSocialMedia', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/social-media-master/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Social Media deleted successfully.');
        dispatch(getSocialMedia());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateSocialMediaStatus = createAsyncThunk('socialMediaSlice/updateSocialMediaStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/social-media-master/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getSocialMedia());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const socialMediaSlice = createSlice({
    name: 'socialMediaSlice',
    initialState: {
        data: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getSocialMedia.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSocialMediaById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default socialMediaSlice.reducer;
