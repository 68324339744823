import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCoupon = createAsyncThunk('manageCouponSlice/getCoupon', async () => {
    try {
        let response = await axios.get('/coupon', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCoupon = createAsyncThunk('manageCouponSlice/createCoupon', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/coupon', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Coupon added successfully.');
        dispatch(getCoupon());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCouponById = createAsyncThunk('manageCouponSlice/getCouponById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/coupon/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCoupon = createAsyncThunk('manageCouponSlice/updateCoupon', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/coupon/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock updated successfully.');
        dispatch(getCoupon());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCoupon = createAsyncThunk('manageCouponSlice/deleteCoupon', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/coupon/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock deleted successfully.');
        dispatch(getCoupon());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCouponStatus = createAsyncThunk('manageCouponSlice/updateCouponStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/coupon/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getCoupon());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const manageCouponSlice = createSlice({
    name: 'manageCouponSlice',
    initialState: {
        data: [],
        option: [],
        selectedData: {}
    },
    reducers: {
        handleSelectedCouponChange: (state, action) => {
            state.selectedData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCoupon.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCouponById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
        builder.addCase(updateCoupon.fulfilled, (state, action) => {
            state.selectedData = {};
            return;
        });
    }
});

export const { handleSelectedCouponChange } = manageCouponSlice.actions;

export default manageCouponSlice.reducer;
