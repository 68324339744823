import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCustomerCategory = createAsyncThunk('customerCategorySlice/getCustomerCategory', async () => {
    try {
        let response = await axios.get('/customer-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerCategoryById = createAsyncThunk('customerCategorySlice/getCustomerCategoryById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/customer-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomerCategory = createAsyncThunk('customerCategorySlice/createCustomerCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/customer-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer Category added successfully.');
        dispatch(getCustomerCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerCategory = createAsyncThunk('customerCategorySlice/updateCustomerCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/customer-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer Category updated successfully.');
        dispatch(getCustomerCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCustomerCategory = createAsyncThunk('customerCategorySlice/deleteCustomerCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/customer-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer Category deleted successfully.');
        dispatch(getCustomerCategory());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerCategoryStatus = createAsyncThunk(
    'customerCategorySlice/updateCustomerCategoryStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/customer-category/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getCustomerCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const customerCategorySlice = createSlice({
    name: 'customerCategorySlice',
    initialState: {
        data: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCustomerCategoryById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default customerCategorySlice.reducer;
