var service = {};

if (process.env.REACT_APP_NODE_ENV === 'production') {
    service.API_URL = 'https://dev-api.instaacard.com';
} else {
    // service.API_URL = 'http://localhost:5005';
    service.API_URL = 'https://dev-api.instaacard.com';

}

export default service;
