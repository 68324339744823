import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getStockIn = createAsyncThunk('stockInSlice/getStockIn', async () => {
    try {
        let response = await axios.get('/stock-in', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createStockIn = createAsyncThunk('stockInSlice/createStockIn', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/stock-in', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock In added successfully.');
        // dispatch(getDesignCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStockIn = createAsyncThunk('stockInSlice/updateStockIn', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/stock-in/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Stock In updated successfully.');
        dispatch(getStockIn());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const stockinSequence = createAsyncThunk('stockInSlice/stockinSequence', async (data = {}) => {
    try {
        let response = await axios.post('/stock-in/sequence', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const stockInSlice = createSlice({
    name: 'stockInSlice',
    initialState: {
        data: []
    },
    extraReducers: (builder) => {
        builder.addCase(getStockIn.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default stockInSlice.reducer;
