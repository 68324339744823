import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getRole = createAsyncThunk('roleSlice/getRole', async () => {
    try {
        let response = await axios.get('/role', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoleOptions = createAsyncThunk('roleSlice/getRoleOptions', async () => {
    try {
        showLoader(true);

        let response = await axios.get('/role?status=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        let options = [];
        response?.data?.forEach((row) => {
            options.push({
                label: row.name,
                value: row.id
            });
        });

        showLoader(false);
        return Promise.resolve(options);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createRole = createAsyncThunk('roleSlice/createRole', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/role', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role added successfully.');
        dispatch(getRole());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getRoleById = createAsyncThunk('roleSlice/getRoleById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/role/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRole = createAsyncThunk('roleSlice/updateRole', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/role/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role updated successfully.');
        dispatch(getRole());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteRole = createAsyncThunk('roleSlice/deleteRole', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/role/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role deleted successfully.');
        dispatch(getRole());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('roleSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/role/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getRole());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const roleSlice = createSlice({
    name: 'roleSlice',
    initialState: {
        data: [],
        options: [],
        selectedRole: {}
    },
    reducers: {
        handleSelectedRole: (state, action) => {
            state.selectedRole = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getRoleOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getRoleById.fulfilled, (state, action) => {
            state.selectedRole = action.payload;
            return;
        });
    }
});

export const { handleSelectedRole } = roleSlice.actions;

export default roleSlice.reducer;
