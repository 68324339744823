import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getProductCategory = createAsyncThunk('productCategorySlice/getProductCategory', async () => {
    try {
        let response = await axios.get('/product-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProductCategoryOption = createAsyncThunk('productCategorySlice/getProductCategoryOption', async () => {
    try {
        let response = await axios.get('/product-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProductCategoryById = createAsyncThunk('productCategorySlice/getProductCategoryById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/product-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProductCategory = createAsyncThunk('productCategorySlice/createProductCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/product-category', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Product Category added successfully.');
        dispatch(getProductCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProductCategory = createAsyncThunk('productCategorySlice/updateProductCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/product-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Product Category updated successfully.');
        dispatch(getProductCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteProductCategory = createAsyncThunk('productCategorySlice/deleteProductCategory', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/product-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product Category deleted successfully.');
        dispatch(getProductCategory());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProductCategoryStatus = createAsyncThunk(
    'productCategorySlice/updateProductCategoryStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/product-category/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getProductCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const productCategorySlice = createSlice({
    name: 'productCategorySlice',
    initialState: {
        data: [],
        option: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getProductCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProductCategoryOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getProductCategoryById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default productCategorySlice.reducer;
