import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// ** ThemeConfig
import themeConfig from '../../../configs/themeConfig';

// Loader Components
import showLoader from '@components/loader';

export const createCustomerCard = createAsyncThunk('customerOrderSlice/createCustomerCard', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/customer-card-mapping', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Card Assigned successfully.');

        // dispatch(getCustomerOrderItems());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerCard = createAsyncThunk('customerOrderSlice/createCustomerCard', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/customer-card-mapping/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Card replaced successfully.');

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOrder = createAsyncThunk('customerOrderSlice/getCustomerOrder', async () => {
    try {
        let response = await axios.get('/customer-order', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomerOrder = createAsyncThunk('customerOrderSlice/createCustomerOrder', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/customer-order', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer Order created successfully.');
        dispatch(getCustomerOrder());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOrderSuccess = createAsyncThunk('customerOrderSlice/getCustomerOrderSuccess', async () => {
    try {
        let response = await axios.get('/customer-order?paymentStatus=success', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOrderPending = createAsyncThunk('customerOrderSlice/getCustomerOrderPending', async () => {
    try {
        let response = await axios.get('/customer-order?paymentStatus=pending', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOrderFailure = createAsyncThunk('customerOrderSlice/getCustomerOrderFailure', async () => {
    try {
        let response = await axios.get('/customer-order?paymentStatus=failure', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOrderItems = createAsyncThunk('customerOrderSlice/getCustomerOrderItems', async (id) => {
    try {
        let response = await axios.get('/order-item/order/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateOrderStatus = createAsyncThunk('customerOrderSlice/updateOrderStatus', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/customer-order/order-status/' + data.id, data.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updatePaymentStatus = createAsyncThunk('customerOrderSlice/updatePaymentStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/customer-order/payment-status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOrderId = createAsyncThunk('customerOrderSlice/getCustomerOrderId', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/customer-order/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDownloadInvoice = createAsyncThunk('customerOrderSlice/getDownloadInvoice', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/order-invoice/' + id, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'invoice.pdf'; // Set the desired filename

        // Trigger a click event on the anchor element to start the download
        a.click();

        // Cleanup: Revoke the Blob URL and remove the temporary anchor element
        window.URL.revokeObjectURL(url);
        a.remove();
        showLoader(false);
        // Return a success message or any data you need
        return Promise.resolve({ message: 'Invoice downloaded successfully' });
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

const initialOrderTab = () => {
    const item = window.localStorage.getItem('orderTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.orderTab;
};

export const customerOrderSlice = createSlice({
    name: 'customerOrderSlice',
    initialState: {
        data: [],
        failedOrders: [],
        successOrders: [],
        pendingOrders: [],
        selectedData: {},
        orderItems: [],
        invoiceDownload: '',
        orderTab: initialOrderTab()
    },
    reducers: {
        removeCardDesign: (state) => {
            state.selectedData = {};
        },
        handleOrderTab: (state, action) => {
            state.orderTab = action.payload;
            localStorage.setItem('orderTab', JSON.stringify(action.payload));
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomerOrder.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getCustomerOrderSuccess.fulfilled, (state, action) => {
            state.successOrders = action?.payload;
            return;
        });

        builder.addCase(getCustomerOrderPending.fulfilled, (state, action) => {
            state.pendingOrders = action?.payload;
            return;
        });

        builder.addCase(getCustomerOrderFailure.fulfilled, (state, action) => {
            state.failedOrders = action?.payload;
            return;
        });

        builder.addCase(getCustomerOrderItems.fulfilled, (state, action) => {
            state.orderItems = action?.payload;
            return;
        });
        builder.addCase(getCustomerOrderId.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
        builder.addCase(getDownloadInvoice.fulfilled, (state, action) => {
            state.invoiceDownload = action.payload;
            return;
        });
    }
});

export const { removeCardDesign, handleOrderTab } = customerOrderSlice.actions;
export default customerOrderSlice.reducer;
