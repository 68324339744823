import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCurrencyMaster = createAsyncThunk('currencyMasterSlice/getCurrencyMaster', async () => {
    try {
        let response = await axios.get('/currency-master', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCurrencyMasterOption = createAsyncThunk('currencyMasterSlice/getCurrencyMasterOption', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/currency-master?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCurrencyMasterById = createAsyncThunk('currencyMasterSlice/getCurrencyMasterById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/currency-master/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCurrencyMaster = createAsyncThunk('currencyMasterSlice/createCurrencyMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/currency-master', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Currency added successfully.');
        dispatch(getCurrencyMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCurrencyMaster = createAsyncThunk('currencyMasterSlice/updateCurrencyMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/currency-master/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Currency updated successfully.');
        dispatch(getCurrencyMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCurrencyMaster = createAsyncThunk('currencyMasterSlice/deleteCurrencyMaster', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/currency-master/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Currency deleted successfully.');
        dispatch(getCurrencyMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCurrencyMasterStatus = createAsyncThunk('currencyMasterSlice/updateCurrencyMasterStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/currency-master/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getCurrencyMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const currencyMasterSlice = createSlice({
    name: 'currencyMasterSlice',
    initialState: {
        data: [],
        option: [],
        selectedData: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrencyMaster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getCurrencyMasterOption.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getCurrencyMasterById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default currencyMasterSlice.reducer;
