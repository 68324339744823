import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getProductMaster = createAsyncThunk('productMasterSlice/getProductMaster', async () => {
    try {
        let response = await axios.get('/product', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProductMasterOptions = createAsyncThunk('productMasterSlice/getProductMasterOptions', async () => {
    try {
        let response = await axios.get('/product?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProductMasterById = createAsyncThunk('productMasterSlice/getProductMasterById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/product/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        console.error('err', err);
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProductMaster = createAsyncThunk('productMasterSlice/createProductMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/product', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Product Master added successfully.');
        dispatch(getProductMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProductMaster = createAsyncThunk('productMasterSlice/updateProductMaster', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/product/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Product Master updated successfully.');
        dispatch(getProductMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteProductMaster = createAsyncThunk('productMasterSlice/deleteProductMaster', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/product/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product Master deleted successfully.');
        dispatch(getProductMaster());

        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProductMasterStatus = createAsyncThunk('productMasterSlice/updateProductMasterStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/product/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getProductMaster());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteProductImage = createAsyncThunk('productMasterSlice/deleteProductImage', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/product-image/' + data?.deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product Image deleted successfully.');
        await dispatch(getProductMasterById(data?.productId));
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        console.error('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const setImagePriority = createAsyncThunk('productMasterSlice/setImagePriority', async (data) => {
    try {
        // showLoader(true);

        let response = await axios.post('/product-image/priority', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Priority updated successfully.');
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const productMasterSlice = createSlice({
    name: 'productMasterSlice',
    initialState: {
        data: [],
        productOptions: [],
        selectedProductMaster: {}
    },
    reducers: {
        removeProduct: (state) => {
            state.selectedProductMaster = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getProductMaster.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProductMasterOptions.fulfilled, (state, action) => {
            state.productOptions = action?.payload;
            return;
        });
        builder.addCase(getProductMasterById.fulfilled, (state, action) => {
            state.selectedProductMaster = action.payload;
            return;
        });
    }
});

export const { removeProduct } = productMasterSlice.actions;
export default productMasterSlice.reducer;
