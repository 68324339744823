import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getProductSubCategory = createAsyncThunk('productSubCategorySlice/getProductSubCategory', async () => {
    try {
        let response = await axios.get('/product-sub-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getProductSubCategoryWithCategory = createAsyncThunk(
    'productSubCategorySlice/getProductSubCategoryWithCategory',
    async (id) => {
        try {
            let response = await axios.get('/product-sub-category/category/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return Promise.resolve(response.data);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const getProductSubCategoryById = createAsyncThunk('productSubCategorySlice/getProductSubCategoryById', async (id) => {
    try {
        showLoader(true);

        let response = await axios.get('/product-sub-category/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProductSubCategory = createAsyncThunk('productSubCategorySlice/createProductSubCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.post('/product-sub-category', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Sub Category added successfully.');
        dispatch(getProductSubCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateProductSubCategory = createAsyncThunk('productSubCategorySlice/updateProductSubCategory', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/product-sub-category/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Sub Category updated successfully.');
        dispatch(getProductSubCategory());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteProductSubCategory = createAsyncThunk(
    'productSubCategorySlice/deleteProductSubCategory',
    async (deleteId, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.delete('/product-sub-category/' + deleteId, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Sub Category deleted successfully.');
            dispatch(getProductSubCategory());

            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const updateProductSubCategoryStatus = createAsyncThunk(
    'productSubCategorySlice/updateProductSubCategoryStatus',
    async (id, { dispatch }) => {
        try {
            showLoader(true);

            let response = await axios.put('/product-sub-category/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Status updated successfully.');
            dispatch(getProductSubCategory());
            showLoader(false);
            return Promise.resolve(response.data);
        } catch (err) {
            showLoader(false);
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

export const productSubCategorySlice = createSlice({
    name: 'productSubCategorySlice',
    initialState: {
        data: [],
        selectedData: {},
        option: []
    },
    extraReducers: (builder) => {
        builder.addCase(getProductSubCategory.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getProductSubCategoryWithCategory.fulfilled, (state, action) => {
            state.option = action?.payload;
            return;
        });
        builder.addCase(getProductSubCategoryById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default productSubCategorySlice.reducer;
