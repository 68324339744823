// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import site from '../views/Site/store';
import productCategory from '../views/Master/ProductCategory/store';
import productSubCategory from '../views/Master/ProductSubCategory/store';
import customerCategory from '../views/Master/CustomerCategory/store';
import fieldCategory from '../views/Master/FieldCategory/store';
import taxMaster from '../views/Master/TaxMaster/store';
import productMaster from '../views/ProductMaster/store';
import shippingRule from '../views/ShippingRules/store';
import currencyMaster from '../views/Master/CurrencyMaster/store';
import customerOrder from '../views/ManageOrder/store';
import customer from '../views/ManageCustomer/store';
import manageCouponSlice from '../views/Coupon/store';
import stockInSlice from '../views/StockIn/store';
import socialMediaSlice from '../views/Master/SocialMedia/store';
import dashboardSlice from '../views/Dashboard/store';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    users,
    roles,
    site,
    productCategory,
    productSubCategory,
    customerCategory,
    fieldCategory,
    taxMaster,
    productMaster,
    shippingRule,
    currencyMaster,
    customerOrder,
    customer,
    manageCouponSlice,
    stockInSlice,
    socialMediaSlice,
    dashboardSlice
};

export default rootReducer;
