import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getDashboardStats = createAsyncThunk('dashboardSlice/getDashboardStats', async () => {
    try {
        let response = await axios.get('/dashboard-stats', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        data: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardStats.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
    }
});

export default dashboardSlice.reducer;
