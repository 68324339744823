import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// ** Axios Import
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import showLoader from '@components/loader';

export const getCustomers = createAsyncThunk('customerSlice/getCustomers', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/customer', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerOptions = createAsyncThunk('customerSlice/getCustomerOptions', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/customer?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerAddress = createAsyncThunk('customerSlice/getCustomerAddress', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/customer/customer-address/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getStateOptions = createAsyncThunk('customerSlice/getStateOptions', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/state', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerById = createAsyncThunk('customerSlice/getCustomerById', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get('/customer/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCustomer = createAsyncThunk('customerSlice/createCustomer', async (data) => {
    try {
        showLoader(true);
        let response = await axios.post('/customer', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer created successfully.');

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomer = createAsyncThunk('customerSlice/updateCustomer', async (data) => {
    try {
        showLoader(true);
        let response = await axios.put('/customer/' + data.id, data.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Customer updated successfully.');

        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteCustomer = createAsyncThunk('customerSlice/deleteCustomer', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.delete('/customer/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Customer deleted successfully.');
        dispatch(getCustomers());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateCustomerStatus = createAsyncThunk('customerSlice/updateCustomerStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put('/customer/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getCustomers());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const customerSlice = createSlice({
    name: 'customerSlice',
    initialState: {
        data: [],
        stateOptions: [],
        customerOptions: [],
        customerAddress: [],
        selectedCustomer: {}
    },
    extraReducers: (builder) => {
        builder.addCase(getCustomers.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });

        builder.addCase(getCustomerOptions.fulfilled, (state, action) => {
            state.customerOptions = action?.payload;
            return;
        });

        builder.addCase(getCustomerAddress.fulfilled, (state, action) => {
            state.customerAddress = action?.payload;
            return;
        });

        builder.addCase(getStateOptions.fulfilled, (state, action) => {
            state.stateOptions = action?.payload;
            return;
        });

        builder.addCase(getCustomerById.fulfilled, (state, action) => {
            state.selectedCustomer = action?.payload;
            return;
        });
    }
});

export default customerSlice.reducer;
